<template>
  <app-icon-btn
    :access="canWorksWithShareProjectLink"
    :icon-name="require('@/assets/icons/link-icon.svg')"
    :is-icon="false"
    :icon-height="isDesktop ? 16 : 30"
    :icon-width="isDesktop ? 16 : 30"
    text="Quick View Link"
    wrapper-class="d-flex align-center gap-3"
    show-tooltip
    right
    @click="copyProjectLink">
    <span
      v-if="!isDesktop"
      class="fs-16">Quick View Link</span>
  </app-icon-btn>
</template>
<script>
import {
  mapMutations,
  mapState,
  mapGetters,
} from 'vuex';

import ProjectsApi from '@/services/graphql/projects';

import { copyToClipBoard } from '@/utils';

export default {
  name: 'ProjectDetailsSharedLink',
  props: {
    projectId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      isSharedLink: 'ProjectDetails/isSharedLink',
      canShareProject: 'UserRoles/canShareProjectLinkWithNonMembers',
    }),
    ...mapState(['role']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    canWorksWithShareProjectLink() {
      return this.canShareProject(this.role);
    },
    isDesktop() {
      return !this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    ...mapMutations({
      setDetailDataOfProject: 'ProjectDetails/setDetailDataOfProject',
    }),
    async copyProjectLink() {
      const { allowed } = this.canWorksWithShareProjectLink ?? {
      };
      if (!allowed) return;
      this.projectSharedLink(true);
    },
    async projectSharedLink(isShared) {
      const { isSharedLink, projectId } = this;
      try {
        if (isSharedLink) {
          return;
        }
        const workspaceId = this.activeWorkspaceId;
        const { data } = await ProjectsApi.projectSharedLink({
          projectId,
          isShared,
          workspaceId,
        });
        this.setDetailDataOfProject(data.projectSharedLink);
      } catch (err) {
        console.log('projectSharedLink', err);
      } finally {
        copyToClipBoard(window.location.href.replace('project', 'shared-project'), 'Link copied for sending');
      }
    },
  },
};
</script>
